<template>
  <div class="store-decorate">
    <div class="cell" v-if="info">
      <div class="title">商店装饰</div>
      <div style="font-size: 12px; color: #a4a7ad; margin-top: 10px">
        定制您的商店主页以展示您最佳商品并推动销售。
      </div>
      <div style="overflow: hidden">
        <div style="width: 1000px; position: relative">
          <img style="width: 100%" :src="computerImg" alt="" />
          <div
            style="
              position: absolute;
              top: 27.2%;
              left: 18.8%;
              width: 62.4%;
              overflow: hidden;
              bottom: 31%;
              border-radius: 10px 10px 0px 0px;
            "
          >
            <iframe
              user-scalable="no"
              :src="'/storeHome?shop_id=' + info.id"
              style="
                width: 1962px;
                height: 1180px;
                transform: translate(-34.2%, -33%) scale(0.32, 0.34);
                background: #fff;
                pointer-events: none;
                overflow: hidden;
                border-radius: 20px 42px 0px 0px;
              "
              scrolling
              width="2000"
            ></iframe>
          </div>

          <!-- <img
            :src="dnbj"
            alt=""
            style="position: absolute; width: 62%; left: 19%; top: 26%"
          /> -->
        </div>
        <div style="position: absolute; top: 400px; left: 1000px; width: 500px">
          <div style="font-size: 24px">当前电脑版装饰</div>
          <div style="margin-top: 10px" v-if="!info.last_publish_time">
            <i
              class="el-icon-warning"
              style="margin-right: 10px; color: #ffbf00"
            ></i>
            <span style="color: #666">您还未发布任何版本，请编辑后发布</span>
          </div>
          <div
            style="margin-top: 10px"
            v-if="info.last_time != info.last_publish_time"
          >
            <i
              class="el-icon-warning"
              style="margin-right: 10px; color: #ffbf00"
            ></i>
            <span style="color: #666"
              >检测到未发布草稿。请前往'编辑装饰'以发布您的草稿</span
            >
          </div>
          <div
            style="
              background: #fafafa;
              padding: 14px;
              font-size: 14px;
              display: inline-block;
              margin: 20px 0;
              line-height: 30px;
              color: #b7b7b7;
            "
          >
            <div>
              最后存储时间：<span style="color: #666">{{
                info.last_time ? info.last_time : "--"
              }}</span>
            </div>
            <div>
              最后发布时间；<span style="color: #666">{{
                info.last_publish_time ? info.last_publish_time : "--"
              }}</span>
            </div>
          </div>
          <div>
            <el-button type="primary" @click="toEdit"
              >编辑装饰 <i class="el-icon-d-arrow-right"></i
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { shopInfo } from "@/api/shop.js";
export default {
  data() {
    return {
      computerImg: require("@/assets/img/app/computer.jpg"),
      dnbj: require("@/assets/img/app/dnbj.png"),
      info: "",
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    toEdit() {
      this.$router.push("editDecorate");
    },
    getInfo() {
      shopInfo().then((res) => {
        if (res.code == 1) {
          this.info = res.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.store-decorate {
  min-width: 1400px;
  .cell {
    padding: 30px;
    background: #fff;
    .title {
      font-size: 18px;
    }
  }
}
</style>